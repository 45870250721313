import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const collectioncreateAction=createAsyncThunk(
    'collection/create',async(reqBody,thunkAPI)=>{
        try {
            const response = await Api.post('school/collection/create',reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })

    export const collectionEditAction=createAsyncThunk(
        'collection/edit',async(reqBody,thunkAPI)=>{
            try {
                const response = await Api.put('school/collection/edit',reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })

    

    export const collectionShareAction=createAsyncThunk(
        'collection/share',async(reqBody,thunkAPI)=>{
            try {
                const response = await Api.post('school/collection/share',reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })


            

    export const collectionShareWithPerticulerStudentAction=createAsyncThunk(
        'collection/collectionShareWithPerticulerStudent',async(reqBody,thunkAPI)=>{
            try {
                const response = await Api.post('school/collection/share/student',reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })





    export const collectionlistAction=createAsyncThunk(
        'collection/list',async(reqBody,thunkAPI)=>{
            try {
                const response = await Api.get(`school/collection/list?search=${reqBody?.searchValue}`)
               
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })


    export const collectiondeleteAction=createAsyncThunk(
        'collection/delete',async(reqBody,thunkAPI)=>{
            try {
                const response = await Api.delete(`school/collection/delete?id=${reqBody}`)
                
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })


        export const collectiondetailsAction=createAsyncThunk(
            'collection/details',async(reqBody,thunkAPI)=>{
                try {
                    const response = await Api.get(`school/collection/details?id=${reqBody}`)
                    
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })

            export const resetCollectionAction = createAction('collection/reset');

        //     export const updateAction=createAsyncThunk(
        //         'class/update',async(reqBody,thunkAPI)=>{
        //             try {
        //                 const response = await Api.put(`/class/update`,reqBody)
                        
        //                 return response.data
        //             } catch (error) {
        //                 return thunkAPI.rejectWithValue(error.response.data);
        //             }
        //         })


        // export const suspendAction=createAsyncThunk(
        //     'class/suspend',async(reqBody,thunkAPI)=>{
        //         try {
        //             const response = await Api.post('/class/suspend',reqBody)
        //             return response.data
        //         } catch (error) {
        //             return thunkAPI.rejectWithValue(error.response.data);
        //         }
        //     })  
                