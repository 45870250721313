import axios from "axios";
// import { store } from "../Redux/store";

// Create an Axios instance with a base URL
const Api = axios.create({
  // baseURL: 'http://192.168.0.86:8000/api/v1/' 
  baseURL: process.env.REACT_APP_BASE_URL
  // baseURL: 'https://peer-api.bosselt.com/api/v1/' 
});


// Add a request interceptor
Api.interceptors.request.use(function (config) {
    // Retrieve the token from local storage or any other storage mechanism
    const token = localStorage.getItem('token');

    // console.log(store)
    // If the token exists, add it to the headers
    if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
    }

    // Do something before request is sent
    return config;
  }, function (error) {
    // Do something with request error
    return Promise.reject(error);
  });

// Add a response interceptor
Api.interceptors.response.use(function (response) {
    // Any status code that lies within the range of 2xx causes this function to trigger
    // Do something with response data
    return response;
  }, function (error) {
    if(error.response.status == 401){
     console.log("401")
            localStorage.clear();
      window.location.href = '/';
    }
    // Any status codes that fall outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  });

export default Api;
