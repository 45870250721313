import { createSlice } from "@reduxjs/toolkit";
import { ForgotPasswordAction, UpdatePasswordAction, resetPasswordAction, verifyOtpAction } from "../Action/PasswordAction";

const initialState = {
    UpdatePasswordStatus:'idle',
    ForgotPasswordStatus:'idle',
    verifyOtpStatus:'idle',
    resetPasswordStatus:'idle'   
}

const passwordSlice = createSlice({
    name: 'password',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(UpdatePasswordAction.fulfilled, (state, action) => {
                state.UpdatePasswordStatus = 'succeeded';
            })            
            .addCase(ForgotPasswordAction.fulfilled, (state, action) => {
                state.ForgotPasswordStatus = 'succeeded';
            })
            
            .addCase(verifyOtpAction.fulfilled, (state, action) => {
                state.verifyOtpStatus = 'succeeded';
            })
     

            .addCase(resetPasswordAction.fulfilled, (state, action) => {
                state.resetPasswordStatus = 'succeeded';
            })


            
    },
});

export default passwordSlice.reducer;



