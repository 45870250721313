import { createSlice } from "@reduxjs/toolkit";
import { DashboardListAction } from "../Action/DashboardAction";



const initialState = {
    dashboardList:{},
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(DashboardListAction.fulfilled, (state, action) => {
                state.dashboardList = action?.payload?.data;  
            })     
             

    },
});

export default dashboardSlice.reducer;








