import React, { Suspense, lazy, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
// import { ToastContainer } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';
import PrivatePublicRoute from "./Components/common/PrivatePublicRoute";
import PrivatedAuthRoutes from "./Components/common/PrivatedAuthRoutes";
import ProtectedRotes from "./Components/common/ProtectedRotes";
import PublicRoutesSuspense from "./Components/Layout/PublicRoutesSuspense";
import PrivateRoutesSuspense from "./Components/Layout/PrivateRoutesSuspense";
import NotFound from "./pages/NotFound";
import { useDispatch, useSelector } from "react-redux";

import { clearTimes, setHours, storeUser } from "./Redux/Slice/authSlice";
import ShowTimer from "./Components/common/ShowTimer";
import { userLogTimeUpdateAction } from "./Redux/Action/AuthAction";
// import { getMessaging, getToken as getFirebaseToken, onMessage } from 'firebase/messaging';
// import { initializeApp } from 'firebase/app';




// Lazy loading the components
const Login = lazy(() => import("./pages/Login"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const FAQs = lazy(() => import("./pages/FAQs"));
const Otp = lazy(() => import("./pages/Otp"));
const Browse = lazy(() => import("./pages/Browse"));
const Playback = lazy(() => import("./pages/Playback"));
const PlayBackdemo = lazy(() => import("./pages/PlayBackdemo"));
const Classes = lazy(() => import("./pages/Classes"));
const ClassDetail = lazy(() => import("./pages/ClassDetail"));
const Students = lazy(() => import("./pages/Students"));
const StudentDetail = lazy(() => import("./pages/StudentDetail"));
const Collections = lazy(() => import("./pages/Collections"));
const CollectionDetail = lazy(() => import("./pages/CollectionDetail"));
const Likes = lazy(() => import("./pages/Likes"));
const Settings = lazy(() => import("./pages/Settings"));
const UpdatePassword = lazy(() => import("./pages/UpdatePassword"));
const HelpAndSupport = lazy(() => import("./pages/HelpAndSupport"));
const ReportAnIssue = lazy(() => import("./pages/ReportAnIssue"));
const Teachers = lazy(() => import("./pages/Teachers"));
const CollegePolicy = lazy(() => import("./pages/CollegePolicy"));
const SellerPolicy = lazy(() => import("./pages/SellerPolicy"));
const SponsorPolicy = lazy(() => import("./pages/SponsorPolicy"));
const VideoShorts = lazy(() => import("./pages/VideoShorts"));
const TeacherDetail = lazy(() => import("./pages/TeacherDetail"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const ResetPassword = lazy(() => import("./pages/ResetPassword"));
const SchoolDetails = lazy(() => import("./pages/SchoolDetails"));
const TeacherDetails = lazy(() => import("./pages/TeacherDetails"));
const TeacherPortalDetail = lazy(() => import("./pages/TeacherPortalDetail"));
const SetPassword = lazy(() => import("./pages/SetPassword"));
const StudentSetPassword = lazy(() => import("./pages/StudentSetPassword"));
const MyLikes = lazy(() => import("./pages/MyLikes"));




function App() {
  const dispatch = useDispatch();
  const userDate = useSelector((state) => state?.authSlice.userData?.payload);
  const hours = useSelector((state) => state?.authSlice.hours)
  const tokenData = localStorage.getItem('token');

  const activeStatus = async (currentDayName) => {
    try {
      const Payload = {
        date:new Date(),
        hours:hours === null ? 0 : hours,
        id: userDate?.activityId,
        day:currentDayName
      };
      const token = localStorage.getItem('token');
      if (token) {
        const response = await dispatch(userLogTimeUpdateAction(Payload));
        if (response?.payload?.statusCode === 200) {
          console.log("day end")
          dispatch(clearTimes());
        }
    }else{
        console.log("no token")
      }
    } catch (error) {
      console.error('Error fetching initial time:', error);
    }
  };


  useEffect(() => {
    // Function to check the time and make the API call if conditions are met
    const checkTimeAndCallApi = () => {
      const currentTime = new Date();
      const currentHour = currentTime.getHours();
      const currentMinute = currentTime.getMinutes();
      const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
      const currentDayName = daysOfWeek[currentTime.getDay()];

      // Check if the time is 11:59 PM
      if (currentHour === 23 && currentMinute === 59) {
        // Check if the user is logged in (token exists in localStorage)
        const token = localStorage.getItem('token');
        if (token) {
          activeStatus(currentDayName)
        } else {
          console.log('User is not logged in.');
        }
      }
    };

    // Set an interval to check the time every minute
    const intervalId = setInterval(checkTimeAndCallApi, 60000); // 60000ms = 1 minute

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);
  
  return (
    <div className="App">
      <>
      {tokenData ? <ShowTimer hours ={hours === null ? 0 : hours}  userDate={userDate}/> :null}
      </>

      <BrowserRouter>

        <Routes>

          <Route element={<PrivatePublicRoute />}>
            <Route path="/college-policy" element={<PublicRoutesSuspense> <CollegePolicy /></PublicRoutesSuspense>} />
            <Route path="/sponsor-policy" element={<PublicRoutesSuspense> <SponsorPolicy /></PublicRoutesSuspense>} />
            <Route path="/seller-policy" element={<PublicRoutesSuspense> <SellerPolicy /></PublicRoutesSuspense>} />

            <Route path="/" element={<PublicRoutesSuspense> <Login /></PublicRoutesSuspense>} />
            <Route path="/otp" element={<PublicRoutesSuspense> <Otp /></PublicRoutesSuspense>} />
            <Route path="/Reset-Password" element={<PublicRoutesSuspense> <ResetPassword /></PublicRoutesSuspense>} />
              <Route path="/forgot-password" element={ <PublicRoutesSuspense> <ForgotPassword /></PublicRoutesSuspense>} />
            <Route path="/set-password/:id" element={<PublicRoutesSuspense> <SetPassword /></PublicRoutesSuspense>} />
            <Route path="/student/set-password/:id" element={<PublicRoutesSuspense> <StudentSetPassword/></PublicRoutesSuspense>} />
          </Route>

          <Route element={<PrivatedAuthRoutes />}>
            <Route path="/VideoShorts/:id" element={<PrivateRoutesSuspense> <VideoShorts /> </PrivateRoutesSuspense>} />
            <Route path="/Students" element={<PrivateRoutesSuspense> <Students /> </PrivateRoutesSuspense>} />
              <Route path="/Dashboard" element={ <PrivateRoutesSuspense> <Dashboard /> </PrivateRoutesSuspense>} />
            <Route path="/faqs" element={<PrivateRoutesSuspense> <FAQs /> </PrivateRoutesSuspense>} />
            <Route path="/browse" element={<PrivateRoutesSuspense> <Browse /> </PrivateRoutesSuspense>} />
            <Route path="/PlayBackdemo/:id" element={<PrivateRoutesSuspense> <PlayBackdemo /> </PrivateRoutesSuspense>} />
            <Route path="/playback/:id" element={<PrivateRoutesSuspense> <Playback /> </PrivateRoutesSuspense>} />
            <Route path="/MyLikes" element={<PrivateRoutesSuspense> <MyLikes /> </PrivateRoutesSuspense>} />
              <Route path="/classes" element={ <PrivateRoutesSuspense> <Classes /> </PrivateRoutesSuspense>} />
              <Route path="/class-detail/:id" element={ <PrivateRoutesSuspense> <ClassDetail /> </PrivateRoutesSuspense>} />
              <Route path="/student-detail/:id" element={ <PrivateRoutesSuspense> <StudentDetail /> </PrivateRoutesSuspense>} />
              <Route path="/collections" element={ <PrivateRoutesSuspense> <Collections /> </PrivateRoutesSuspense>}  />
              <Route path="/collection-detail/:id" element={ <PrivateRoutesSuspense> <CollectionDetail /> </PrivateRoutesSuspense>} />
              <Route path="/likes" element={  <PrivateRoutesSuspense> <Likes /> </PrivateRoutesSuspense>} />
              <Route path="/settings" element={ <PrivateRoutesSuspense> <Settings /> </PrivateRoutesSuspense>} />
              <Route path="/update-password" element={ <PrivateRoutesSuspense> <UpdatePassword /> </PrivateRoutesSuspense>} />
            <Route path="/help-support" element={<PrivateRoutesSuspense> <HelpAndSupport /> </PrivateRoutesSuspense>} />
            <Route path="/report-issue" element={<PrivateRoutesSuspense> <ReportAnIssue /> </PrivateRoutesSuspense>} />
            <Route path="/teacher-profile" element={<PrivateRoutesSuspense> <TeacherDetails /> </PrivateRoutesSuspense>} />

            <Route element={<ProtectedRotes />}>
                <Route path="/teachers" element={ <PrivateRoutesSuspense> <Teachers /> </PrivateRoutesSuspense>} />
              <Route path="/school-profile" element={<PrivateRoutesSuspense> <SchoolDetails /> </PrivateRoutesSuspense>} />
            </Route>

            <Route path="/teacher-detail/:id" element={<PrivateRoutesSuspense> <TeacherDetail /> </PrivateRoutesSuspense>} />

            <Route path="/teacher-portal-detail" element={<PrivateRoutesSuspense> <TeacherPortalDetail /> </PrivateRoutesSuspense>} />
          </Route>

          <Route path="*" element={<NotFound />} />
        </Routes>
        <Toaster />
        {/* <ToastContainer /> */}
      </BrowserRouter>
    </div>
  );
}

export default App;
