import { createSlice } from "@reduxjs/toolkit";
import { SaveAction, likedVideoDetailAction, likesAction, likeslistAction, resetLikesAction } from "../Action/LikesAction";

const initialState = {
    likesData: {},
    likesStatus:'idle',
    SaveStatus:"idle",
    likedVideoDetail:{}
}

const likesSlice = createSlice({
    name: 'likes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(likesAction.fulfilled, (state, action) => {
                state.likesStatus = 'succeeded';
            })
        
            .addCase(SaveAction.fulfilled, (state, action) => {
                state.SaveStatus = 'succeeded';
            })
            
            .addCase(likeslistAction.fulfilled, (state, action) => {
                state.likesData = action?.payload?.data;
            })


            .addCase(likedVideoDetailAction.fulfilled, (state, action) => {
                state.likedVideoDetail = action?.payload?.data;
            })
            
            .addCase(resetLikesAction, () => initialState);

    },
});

export default likesSlice.reducer;


