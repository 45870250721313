import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const PrivatePublicRoute = () => {
  const authToken = useSelector((state) => state?.authSlice.userData?.payload?.token)
  const isTemporaryPassword = useSelector((state) => state?.authSlice.userData?.payload?.isTemporaryPassword)
  return (
    !authToken ? <Outlet /> : <Navigate to={isTemporaryPassword ? '/update-password' : '/Dashboard'} />
  )
}

export default PrivatePublicRoute


