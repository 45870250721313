import React from 'react'
import { useSelector } from 'react-redux'
import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRotes = () => {
    const dataofstore = useSelector((state) => state?.authSlice.userData?.payload)

    return (
        dataofstore && dataofstore?.role !== "Teacher" ? <Outlet /> : <Navigate to='/' />
    )
}

export default ProtectedRotes



