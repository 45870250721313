// PublicRoutesSuspense.js
import React, { Suspense } from 'react';
import { Spinner } from 'react-bootstrap';

const PublicRoutesSuspense = ({ children }) => (
  <div className='top-fix-sec'>
  <Suspense fallback={
  <div className='spin-loader' >
    <Spinner animation="grow" style={{background:"#d200a5"}} />
    <Spinner animation="grow" style={{background:"#d200a5"}} />
    <Spinner animation="grow" style={{background:"#d200a5"}} />
    <Spinner animation="grow"  style={{background:"#2800ea"}} />
    <Spinner animation="grow" style={{background:"#2800ea"}} />
    <Spinner animation="grow" style={{background:"#2800ea"}} />
    <Spinner animation="grow" style={{background:"#bbc6ff"}} />
    </div>
  }>
    {children}
  </Suspense>
  </div>
);

export default PublicRoutesSuspense;
