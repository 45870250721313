import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const ReportVideoAction=createAsyncThunk(
    'Report/reportVideo',async(reqBody,thunkAPI)=>{
        try {
            const response = await Api.post('school/content/reportVideo',reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })




    export const ReportIssueAction=createAsyncThunk(
        'Report/reportIssue',async(reqBody,thunkAPI)=>{
            try {
                const response = await Api.post('school/auth/report',reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })




        export const resetReportAction = createAction('likes/reset');



    