import { createSlice } from "@reduxjs/toolkit";
import { faqListAction } from "../Action/FaqAction";

const initialState = {
    faquestions: [],
}

const faqSlice = createSlice({
    name: 'faq',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(faqListAction.fulfilled, (state, action) => {
                state.faquestions = action?.payload?.data;
            })
    },
});

export default faqSlice.reducer;