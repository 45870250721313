import React, { useState, useEffect } from 'react';
import { IdleTimerProvider } from 'react-idle-timer';
import { useDispatch } from 'react-redux';
import { setHours } from '../../Redux/Slice/authSlice';
import { userLogTimeUpdateAction } from '../../Redux/Action/AuthAction';


const ShowTimer = ({ hours, userDate }) => {
  const dispatch = useDispatch();
  const [seconds, setSeconds] = useState(hours);
  const [isActive, setIsActive] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsLoggedIn(true); // Set isLoggedIn based on token presence
    } else {
      setIsLoggedIn(false); // Set isLoggedIn to false if no token found
    }
  }, []);

  useEffect(() => {
    let interval = null;

    if (isLoggedIn && isActive) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          const newSeconds = prevSeconds + 1;
          dispatch(setHours(newSeconds));
          return newSeconds;
        });
      }, 1000);
    }

    return () => {
      if (interval) clearInterval(interval);
    };
  }, [isActive, isLoggedIn, dispatch]);

  const onIdle = () => {
    const currentTime = new Date();
    const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const currentDayName = daysOfWeek[currentTime.getDay()];


    activeStatus(currentDayName);
    setIsActive(false);
  };

  const onActive = () => {
    setIsActive(true);
  };

  const activeStatus = async (currentDayName) => {
    try {
      const Payload = {
        date: new Date(),
        hours: hours,
        id: userDate?.activityId,
        day:currentDayName
      };
      const response = await dispatch(userLogTimeUpdateAction(Payload));
      if (response?.payload?.statusCode === 200) {
        console.log("time Updated");
      }
    } catch (error) {
      console.error('Error fetching initial time:', error);
    }
  };

  if (!isLoggedIn) {
    return null; // Return null if not logged in (no token)
  }

  return (
    <IdleTimerProvider
      timeout={1000 * 60 * 1} // 1 minute
      onIdle={onIdle}
      onActive={onActive}
    >
      <div>
        {/* <h1>Timer: {seconds} seconds</h1>
        <p>{isActive ? 'Timer is running' : 'Timer is paused'}</p> */}
      </div>
    </IdleTimerProvider>
  );
};

export default ShowTimer;

