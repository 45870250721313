import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const likesAction=createAsyncThunk(
    'likes/create',async(reqBody,thunkAPI)=>{
        try {
            const response = await Api.post('school/content/like',reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



    export const SaveAction=createAsyncThunk(
        'likes/save',async(reqBody,thunkAPI)=>{
            try {
                const response = await Api.post('school/content/save',reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })
    



        export const resetLikesAction = createAction('likes/reset');

    export const likeslistAction=createAsyncThunk(
        'likes/list',async(reqBody,thunkAPI)=>{
            try {
                const response = await Api.get(`school/content/likes?search=${reqBody?.searchValue}&group=${reqBody?.selectedClass}&categoryId=${reqBody?.selectedcategoryId}`)
               
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
        })

    

        export const likedVideoDetailAction = createAsyncThunk(
            'likes/video', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.get(`school/content/like-video?search=${reqBody?.searchValue}&videoId=${reqBody.videoId}`)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
            })
        




    // export const classdetailsAction=createAsyncThunk(
    //         'class/details',async(reqBody,thunkAPI)=>{
    //             try {
    //                 const response = await Api.get(`/school/class/details?id=${reqBody}`)
                    
    //                 return response.data
    //             } catch (error) {
    //                 return thunkAPI.rejectWithValue(error.response.data);
    //             }
    //         })


 