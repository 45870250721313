import { createSlice } from "@reduxjs/toolkit";
import { VideoviewAction, allCategoryList, browseAddToCollectionAction, browseCategoryDetailAction, browseCategoryDetailTestAction, browseCategoryDetailTestDetailAction, browseDetailAction, getBrowseCatagoryAction, resetBrowseAction } from "../Action/BrowseAction";

const initialState = {
    categories: [],
    data: [],
    browseDetails: {},
    browseCatagoryDetail: {},
    browseAddToCollectionStatus:"idle",
    browseCategoryDetailTestStatus:{},
    browseCategoryDetailTestDetailStatus:"idle",
    VideoviewStatus:"idle"
}
const browseSlice = createSlice({
    name: 'browse',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(allCategoryList.fulfilled, (state, action) => {
                state.categories = action?.payload?.data;
            })
            .addCase(browseDetailAction.fulfilled, (state, action) => {
                state.browseDetails = action?.payload?.data;
            })
            .addCase(getBrowseCatagoryAction.fulfilled, (state, action) => {
                state.data = action?.payload?.data;
            })
            .addCase(browseCategoryDetailAction.fulfilled, (state, action) => {
                state.browseCatagoryDetail = action?.payload?.data;
            })
              
            .addCase(browseAddToCollectionAction.fulfilled, (state, action) => {
                state.browseAddToCollectionStatus = "Successfully"
            })

            .addCase(browseCategoryDetailTestAction.fulfilled, (state, action) => {
                state.browseCategoryDetailTestStatus = action?.payload?.data;
            })

            .addCase(browseCategoryDetailTestDetailAction.fulfilled, (state, action) => {
                state.browseCategoryDetailTestDetailStatus = "Successfully"
            })
            
            .addCase(VideoviewAction.fulfilled, (state, action) => {
                state.VideoviewStatus = "Successfully"
            })
            .addCase(resetBrowseAction, () => initialState);
    },
});

export default browseSlice.reducer;




