import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { storeUser } from '../../Redux/Slice/authSlice'

const PrivatedAuthRoutes = () => {
 console.log("pp")
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const localStorageToken = localStorage.getItem('token')
  const authToken =  useSelector((state) => state?.authSlice.userData?.payload?.token)

    if (!localStorageToken) {
      localStorage.clear();
      dispatch(storeUser({}))
      navigate("/");
      // return null
}

  return (
    authToken ? <Outlet/> : <Navigate to='/'/>
  )
}

export default PrivatedAuthRoutes





