import { createSlice } from "@reduxjs/toolkit";
import { resetTeacherAction, teacheResetPassAction, teacherCreateAction, teacherDailyUsageAction, teacherDeleteAction, teacherDetailAction, teacherEditAction, teacherEditProfileSettingsAction, teacherListAction, teacherSuspendAction } from "../Action/TeacherAction";

const initialState = {
    teachers: [],
    teacherDetail:{},
    createTeacherStatus:'idle',
    teacherDetailstatus:'idle',
    teacherDeleteStatus:"idle",
    teacherSuspendStatus:"idle",
    teacherEditStatus:"idle",
    teacheResetPassStatus:"idle",
    teacherEditProfileSettingsStatus:"idle",
    teacherDailyUsageDetail :{}
}

const teacherSlice = createSlice({
    name: 'teacher',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(teacherListAction.fulfilled, (state, action) => {
                state.teachers = action?.payload?.data;
            })

            .addCase(teacherCreateAction.fulfilled, (state, action) => {
                state.createTeacherStatus = 'succeeded';
            })

            .addCase(teacherDetailAction.fulfilled, (state, action) => {
                state.teacherDetail = action?.payload?.data;
            })
          
            .addCase(teacherDeleteAction.fulfilled, (state, action) => {
                state.createTeacherStatus = 'succeeded';
            })
            
            .addCase(teacherSuspendAction.fulfilled, (state, action) => {
                state.teacherSuspendStatus = 'succeeded';
            })
           
            .addCase(teacherEditAction.fulfilled, (state, action) => {
                state.teacherEditStatus = 'succeeded';
            })
           

            .addCase(teacherDailyUsageAction.fulfilled, (state, action) => {
                state.teacherDailyUsageDetail = action?.payload?.data;
            })

            

            .addCase(teacherEditProfileSettingsAction.fulfilled, (state, action) => {
                state.teacherEditProfileSettingsStatus = 'succeeded';
            })
            .addCase(teacheResetPassAction.fulfilled, (state, action) => {
                state.teacheResetPassStatus = 'succeeded';
            })

            .addCase(resetTeacherAction, () => initialState);
            
            
            

    },
});

export default teacherSlice.reducer;




