import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Link } from "react-router-dom";

export default function NotFound() {
  
  return (
    <div className="cmn-gradient">
      <div className="sign-shape">
        <Container fluid>
          <Row className="box-center">
            <Col lg={5} className="m-auto">
              <div className="login-cmn-box">
                <div className="login-box-inner-wrap text-center">
                  <h2 className="">404 - Page Not Found</h2>
                  <p>Sorry, the page you are looking for could not be found.</p>
               
                  <Link to="/Dashboard"
                  className="cmn-pink w-10 text-center"
                    >
                    
                     Back to Dashboard
                    </Link>
              
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}



