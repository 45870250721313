import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";


export const UpdatePasswordAction = createAsyncThunk(
    'UpdatePassword', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.put('school/teacher/change-password',reqBody)
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }

    })
    

    export const verifyOtpAction = createAsyncThunk(
        'sendotp', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.post('school/auth/logout',reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
    
        })



    export const ForgotPasswordAction = createAsyncThunk(
        'forgotpassword', async (reqBody, thunkAPI) => {
            try {
                const response = await Api.post('school/auth/forgot-password',reqBody)
                return response.data
            } catch (error) {
                return thunkAPI.rejectWithValue(error.response.data);
            }
    
        })


        export const resetPasswordAction = createAsyncThunk(
            'resetPassword', async (reqBody, thunkAPI) => {
                try {
                    const response = await Api.post('school/auth/reset-password',reqBody)
                    return response.data
                } catch (error) {
                    return thunkAPI.rejectWithValue(error.response.data);
                }
        
            })


                 
                

