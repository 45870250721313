import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import authSlice from './Slice/authSlice';
import passwordSlice from './Slice/passwordSlice';
import classSlice from './Slice/classSlice';
import teacherSlice from './Slice/teacherSlice';
import studentSlice from './Slice/studentSlice';
import browseSlice from './Slice/browseSlice';
import collectionSlice from './Slice/collectionSlice';
import faqSlice from './Slice/faqSlice';
import likesSlice from './Slice/likesSlice';
import reportSlice from './Slice/reportSlice';
import notificationSlice from './Slice/notificationSlice';
import dashboardSlice from './Slice/dashboardSlice';

const reducers = combineReducers({
  authSlice: authSlice,
  passwordSlice: passwordSlice,
  classSlice: classSlice,
  teacherSlice: teacherSlice,
  studentSlice: studentSlice,
  browseSlice: browseSlice,
  collectionSlice: collectionSlice,
  faqSlice: faqSlice,
  likesSlice:likesSlice,
  reportSlice:reportSlice,
  notificationSlice:notificationSlice,
  dashboardSlice:dashboardSlice
})

// Persist config
const persistConfig = {
  key: 'root',
  storage,
};

// Combine reducers and persist them
const rootReducer = persistReducer(persistConfig, reducers)

// Configure the store with persisted reducers
const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create a persistor
const persistor = persistStore(store);

export { store, persistor };
