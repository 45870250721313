import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../../BaseUrlConfigration/ApiConfig";

export const NotificationSliceListAction = createAsyncThunk(
    'Notification/list', async (reqBody, thunkAPI) => {
        try {
            const response = await Api.get('school/auth/notification')
            return response.data
        } catch (error) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    })



export const resetNotificationAction = createAction('browse/reset');