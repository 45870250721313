import { createSlice } from "@reduxjs/toolkit";
import { collectionEditAction, collectionShareAction, collectionShareWithPerticulerStudentAction, collectioncreateAction, collectiondeleteAction, collectiondetailsAction, collectionlistAction, resetCollectionAction } from "../Action/CollectionAction";

const initialState = {
    collectionData: {},
    collectioncreateStatus:'idle',
    collectiondeleteStatus:"idle",
    collectionShareStatus:"idle",
    collectionShareWithPerticulerStudentStatus:"idle",
    collectionEditStatus:"idle",
    collectionDetails:{}
}

const collectionSlice = createSlice({
    name: 'collection',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(collectionlistAction.fulfilled, (state, action) => {
                state.collectionData = action?.payload?.data;
            })

            .addCase(collectionShareAction.fulfilled, (state, action) => {
                state.collectionShareStatus = 'succeeded';
            })
            
            .addCase(collectionEditAction.fulfilled, (state, action) => {
                state.collectionEditStatus = 'succeeded';
            })

            .addCase(collectioncreateAction.fulfilled, (state, action) => {
                state.collectioncreateStatus = 'succeeded';
            })
            .addCase(collectiondeleteAction.fulfilled, (state, action) => {
                state.collectiondeleteStatus = 'succeeded';
            })

            .addCase(collectiondetailsAction.fulfilled, (state, action) => {
                state.collectionDetails =  action?.payload?.data;
            })


            .addCase(collectionShareWithPerticulerStudentAction.fulfilled, (state, action) => {
                state.collectionShareWithPerticulerStudentStatus = 'succeeded';
            })



            
            .addCase(resetCollectionAction, () => initialState);
            
            

    },
});

export default collectionSlice.reducer;


