import { createSlice } from "@reduxjs/toolkit";
import { SetPasswordAction, UserInfoEditAction, activityListAction, schoolDetailAction, signinAction, userLogTimeUpdateAction } from "../Action/AuthAction";
const initialState = {
  hours: 0,
  userData: {},
  SetPasswordStatus: "idle",
  UserInfoEditStatus: "idle",
  userLogTimeUpdateStatus: "idle",
  schoolDetail: {},
  activityList: []
}


const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    storeUser: (state, payload) => {
      state.userData = payload
    },
    setHours: (state,payload) => {
      state.hours = payload?.payload
    },
    clearTimes: (state) => {
      state.hours = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(signinAction.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(signinAction.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(signinAction.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(SetPasswordAction.fulfilled, (state, action) => {
        state.SetPasswordStatus = 'succeeded';
      })
      .addCase(UserInfoEditAction.fulfilled, (state, action) => {
        state.UserInfoEditStatus = 'succeeded';
      })

      .addCase(schoolDetailAction.fulfilled, (state, action) => {
        state.schoolDetail = action.payload.data
      })

      .addCase(activityListAction.fulfilled, (state, action) => {
        state.activityList = action.payload.data
      })

      .addCase(userLogTimeUpdateAction.fulfilled, (state, action) => {
        state.userLogTimeUpdateStatus = 'succeeded'
      })







  },
});

export const { storeUser, storeToken, setHours, clearTimes } = authSlice.actions
export default authSlice.reducer;