import { createSlice } from "@reduxjs/toolkit";
import { NotificationSliceListAction, resetNotificationAction } from "../Action/NotificationAction";


const initialState = {
    NotificationList: [],
}

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(NotificationSliceListAction.fulfilled, (state, action) => {
                state.NotificationList = action?.payload?.data;
            })

            .addCase(resetNotificationAction, () => initialState);
    },
});

export default notificationSlice.reducer;
