import { createSlice } from "@reduxjs/toolkit";
import { ReportIssueAction, ReportVideoAction, resetReportAction } from "../Action/ReportAction";


const initialState = {
    ReportVideoStatus:'idle',
    ReportIssueStatus:"idle"
}

const reportSlice = createSlice({
    name: 'likes',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(ReportVideoAction.fulfilled, (state, action) => {
                state.ReportVideoStatus = 'succeeded';
            })
        

            .addCase(ReportIssueAction.fulfilled, (state, action) => {
                state.ReportIssueStatus = 'succeeded';
            })
        
           
            
            .addCase(resetReportAction, () => initialState);

    },
});

export default reportSlice.reducer;


